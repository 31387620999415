import {
  createBehaviorSubject,
  BehaviorSubject,
  BehaviorSubjectSubscribe
} from 'packages/behavior-subject';
import { AcpEnvironment } from 'apps/acp/packages/acp-config';
import { VariantLogo } from 'apps/acp/variants/acp-variant';
import { LogoLocatorContext } from './context';
import { bsSwitchMap } from 'packages/behavior-subject/fn';
import { BrandingFetcherInterface } from 'apps/acp/packages/branding-fetcher';
import React from 'react';

// This is here so typescript doesn't complain about System having no types
// DO NOT FOLLOW THIS PATTERN. This is an escape hatch that should be solved
// via an abstraction over systemjs if we need to do this elsewhere.
declare const System: any;

const resolveLogo = (path: string, variantId?: string): string | undefined => {
  try {
    return variantId
      ? System.resolve(`tenant-assets/${variantId}/assets/${path}`)
      : System.resolve(`tenant-assets/assets/${path}`);
  } catch (err) {
    return variantId && path
      ? System.resolve(`tenant-assets/assets/${path}`)
      : undefined;
  }
};

export function logoLocatorBehaviorSubjectFactory({
  acpEnvironment,
  brandingFetcher,
  ErrorHandling
}: {
  acpEnvironment: BehaviorSubjectSubscribe<AcpEnvironment>;
  brandingFetcher: BehaviorSubjectSubscribe<
    BrandingFetcherInterface | undefined
  >;
  ErrorHandling: React.FC;
}): BehaviorSubject<LogoLocatorContext> {
  let bank: string;

  const getMostRelevantLogo = (logos: VariantLogo[], mobile: boolean) => {
    return mobile
      ? logos.find((logo) => logo.mobile || (logo.mobile && logo.bank === bank))
      : logos.find((logo) => logo.bank === bank);
  };

  const [dispatch, addListener] = createBehaviorSubject<LogoLocatorContext>({});
  bsSwitchMap(
    acpEnvironment,
    async (acpEnv: AcpEnvironment) => {
      return brandingFetcher((branding) => {
        bank = branding?.bank ? branding.bank.toLowerCase() : 'meta';
        const brandName = branding?.brand.name;

        const logo = getMostRelevantLogo(
          acpEnv.config.logoOverrides || [],
          false
        );
        const mobileLogo = getMostRelevantLogo(
          acpEnv.config.logoOverrides || [],
          true
        );
        const path = resolveLogo(
          (logo || { file: 'logo.svg' }).file,
          acpEnv.config.variant.split('/')[1]
        );
        const mobilePath = resolveLogo(
          mobileLogo ? mobileLogo.file : (undefined as any),
          acpEnv.config.variant.split('/')[1]
        );

        if (path) {
          dispatch({
            path,
            mobilePath,
            brandName
          });
        }
      });
    },
    () => ErrorHandling
  );

  return [dispatch, addListener];
}
