import { createElement, FC, Fragment } from 'react';
import { useLogo } from './context';
import { MobileLogo, DesktopLogo } from './styles/logo.ccm.css';

interface LogoProps {
  maxWidth?: string;
  alt?: string;
}

export const Logo: FC<LogoProps> = ({ maxWidth, alt }) => {
  const logo = useLogo();
  return (
    <Fragment>
      {logo.mobilePath && (
        <MobileLogo.img
          loading="lazy"
          src={logo.mobilePath}
          style={{ maxWidth: maxWidth || '208px' }}
          alt={alt || `${logo.brandName} logo`}
        />
      )}
      {logo.path && (
        <DesktopLogo.img
          loading="eager"
          src={logo.path}
          style={{ maxWidth: maxWidth || '270px' }}
          alt={alt || `${logo.brandName} logo`}
        />
      )}
      {!logo.mobilePath && !logo.path && null}
    </Fragment>
  );
};
