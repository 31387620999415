import {
  FC,
  createElement,
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';

export interface LogoLocatorContext {
  path?: string;
  mobilePath?: string;
  brandName?: string;
}

export const LogoContext = createContext<LogoLocatorContext>(null as any);

export interface LogoProviderProps {
  logoLocator: BehaviorSubjectSubscribe<LogoLocatorContext>;
}

/**
 * Takes a logoLocator BehaviorSubject and returns a context provider for
 * subscribing to logo updates.
 */
export const LogoProvider: FC<LogoProviderProps> = ({
  logoLocator,
  children
}) => {
  const [logo, setLogo] = useState<LogoLocatorContext>({});
  const unsubscribeLogoLocator = useRef((): void => void 0);

  useEffect(() => {
    unsubscribeLogoLocator.current = logoLocator((newLogo) => {
      if (
        newLogo.path !== logo?.path ||
        newLogo.mobilePath !== logo?.mobilePath
      ) {
        setLogo(newLogo);
      }
    });

    return () => {
      unsubscribeLogoLocator.current();
    };
  }, [logo, logoLocator]);

  return <LogoContext.Provider value={logo}>{children}</LogoContext.Provider>;
};

export const useLogo = () => useContext(LogoContext);
