
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.DesktopLogo_9f6a89178c88b5d1,.MobileLogo_f7a0f71e6ec215e4,.MobileLogo_f7a0f71e6ec215e4+.DesktopLogo_9f6a89178c88b5d1{max-height:32px}@media (min-width:600px){.DesktopLogo_9f6a89178c88b5d1{display:block;max-height:40px}.MobileLogo_f7a0f71e6ec215e4{display:none}}@media (max-width:599px){.MobileLogo_f7a0f71e6ec215e4+.DesktopLogo_9f6a89178c88b5d1{display:none}.DesktopLogo_9f6a89178c88b5d1{max-width:208px!important}.MobileLogo_f7a0f71e6ec215e4{display:block}}`;
styleInject(_css)

/** MobileLogo Props */
export type MobileLogoCCM = {
  /** MobileLogo Component Custom Properties */
  // No custom properties found

  /** MobileLogo Modifier Flags */
  // No modifiers classes found
};
/** Base MobileLogo component */
export const MobileLogo: ComponentCreator<MobileLogoCCM> = createComponentCreator({
  "name": "MobileLogo",
  "base": "MobileLogo_f7a0f71e6ec215e4",
  "prop": {},
  "mod": {}
});


/** DesktopLogo Props */
export type DesktopLogoCCM = {
  /** DesktopLogo Component Custom Properties */
  // No custom properties found

  /** DesktopLogo Modifier Flags */
  // No modifiers classes found
};
/** Base DesktopLogo component */
export const DesktopLogo: ComponentCreator<DesktopLogoCCM> = createComponentCreator({
  "name": "DesktopLogo",
  "base": "DesktopLogo_9f6a89178c88b5d1",
  "prop": {},
  "mod": {}
});

